.login.modal {
    max-width: 650px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    background-color: white;
    height: 500px;
    top: 0;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.5);

    @media (max-width: 650px) {
        width: 100%;
        border-radius: 0px;
    }

    &.show {
        transform: translate(-50%, 0%);
        top: 40px;
        transition: all .2s ease-out;

        @media (max-width: 650px) {
            top: 0px;
        }
    }

    button.close {
        opacity: 1;

        &:hover {
            opacity: 1;
        }

        &:focus {
            outline: none;
        }

        .close-container {
            width: 30px;
            height: 20px;
            cursor: pointer;
            position: relative;
            display: block;
            margin-top: 8px;

            span {
                background-color: $grey-d2;
                z-index: 5;
                height: 3px;
                width: 100%;
                border-radius: 9px;
                position: absolute;
                left: 1px;
                top: calc(50% - 2px);

                &:nth-child(1) {
                    -webkit-transform: rotate(45deg);
                    -moz-transform: rotate(45deg);
                    -o-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

                &:nth-child(2) {
                    -webkit-transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .login-container {
        .title-container {
            display: flex;
            align-items: center;
            margin-bottom: 80px;

            svg {
                margin-right: 20px;

                &>g>g {
                    fill: $font-color;
                }
            }

            p {
                font-size: 24px;
                margin: 0px;
            }
        }

        form {
            display: flex;
            flex-direction: column;

            .login_errors {
                color: $red;
                margin-bottom: 20px;
                margin-top: -20px;
            }

            .input-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;

                label {
                    font-size: 14px;
                    margin-bottom: 0px;
                    margin-bottom: 8px;
                }

                input {
                    height: 46px;
                    border-radius: 4px;
                    border: 1px $grey solid;
                    padding: 0px 16px;

                    &:focus {
                        outline: none;
                    }
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input[type=number] {
                    -moz-appearance: textfield;
                }
            }

            input[type=submit] {
                height: 55px;
                background-color: $blue;
                width: 238px;
                margin: 0 auto;
                color: $white;
                border-radius: 40px;
                display: block;
                font-size: 18px;
                font-weight: 600;
            }

            .login-electronic-id {
                .alert {
                    margin-top: 20px;
                }
            }

            .login-security-code {

                .security-code {
                    border: 1px solid $blue;
                    border-radius: 8px;
                    align-items: center;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    margin-top: 7px;

                    #code {
                        font-size: 36px;
                        font-weight: 700;
                        margin-bottom: 12px;
                    }

                    .loader {
                        width: 21px;
                        height: 21px;
                        border: 3px solid $blue;
                        border-bottom-color: transparent;
                        border-radius: 50%;
                        display: inline-block;
                        box-sizing: border-box;
                        animation: rotation 1s linear infinite;
                    }

                    @keyframes rotation {
                        0% {
                            transform: rotate(0deg);
                        }

                        100% {
                            transform: rotate(360deg);
                        }
                    }
                }

                #go-back {
                    color: $blue;
                    font-size: 14px;
                    cursor: pointer;
                    text-align: center;
                }

                .disclaimer {
                    font-size: 15px;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .login.modal {
        .login-container {
            .title-container {
                margin-bottom: 70px !important;
            }
            form {
                .login-security-code {
                    .security-code {
                        padding: 15px !important;
                    }
                    .disclaimer {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .login.modal {
        .login-container {
            .title-container {
                margin-bottom: 60px !important;
            }
            form {
                .login-security-code {
                    .disclaimer {
                        margin-bottom: 10px !important;
                    }
                }
            }
        }
    }
}