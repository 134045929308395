[dir="rtl"] {
    .answer-checkbox{
        display: flex;
        input {
            order: 2;
        }
        label{
            order: 1;
            text-align: right;
            padding-right: 38px;
            width: 100%;
            &::before{
                left: unset !important;
                right: -16px;
            }
            svg {
                left: unset !important;
                right: -4px;
    
            }
        }
    }
    .title, .text {
        text-align: right;
    }
}


.page-exampage{
    .readSpeaker{
        position: absolute;
        min-width: 375px;
        left: 50%;
        top: 140px;
        transform: translateX(-50%);
    }
}

.exam {
    .exam-title {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 59px;
        font-size: 20px;
        line-height: 26px;
        color: white;
        text-transform: uppercase;
    }

    .button {
        height: 67px;
        width: 240px;
        border-radius: 35px;
        box-shadow: 0 12px 48px 0 rgba(0,0,0,0.15);
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        color: $white;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            text-decoration: none;
        }
        &.go-over {
            background-color: $yellow;
        }
        &.re-do, &.start {
            background-color: $white;
            color: $font-color;
        }
        &.onward, &.re-read {
            background-color: rgba($black, .4);
        }
    }
    
    .question-container {
        @include max-width-small;
        .question {
            min-height: calc(100vh - 118px);
            &.hidden {
                display: none;
            }
            .question-wrapper {
                width: 100%;
                margin: 80px auto 0px;
                border-radius: 16px;
                box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
                background-color: white;
                position: relative;
                @include media-breakpoint-down(sm) {
                    margin: 40px auto 0px;
                }
                &.checkbox {
                    padding-bottom: 24px;
                }
                &.radio {
                    padding-bottom: 58px;
                }
                .title {
                    padding: 24px 32px 16px;
                    font-size: 22px;
                    font-weight: 600;
                    line-height: 25px;
                }
                .text {
                    padding: 0px 32px 32px;
                    font-size: 16px;
                    line-height: 23px;
                }
                .img-container {
                    background-color: $blue;
                    border-radius: 16px;
                    width: calc(100% - 64px);
                    margin: 0 auto;
                    padding: 24px;
                    margin-bottom: 32px;
                    img {
                        max-width: 100%;
                    }
                }
                .answer-radio {
                    display: flex;
                    width: 100%;
                    justify-items: center;
                    position: absolute;
                    bottom: 0;
                    .answer-container {
                        padding: 16px 32px;
                        border-top: 1px solid $grey-l1;
                        width: 100%;
                        text-align: center;
                        position: relative;
                        &:nth-of-type(1) {
                            border-right: 1px solid $grey-l1;
                        }
                        .radio-container {
                            width: fit-content;
                            margin: auto 0;
                            input {
                                opacity: 0;
                                width: 100%;
                                position: absolute;
                                height: 100%;
                                z-index: 5;
                                left: 0px;
                                top: 0px;
                                &:hover {
                                    cursor: pointer;
                                }
                                &:checked + label::after{
                                    background-color: $blue;
                                }
                                &:checked + label{
                                    font-weight: 600;
                                    &::before{
                                        border: 1px solid $font-color;
                                    }
                                }
                            }
                            label {
                                position: relative;
                                margin-bottom: 0px;
                                font-size: 20px;
                                line-height: 23px;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 20px;
                                    height: 20px;
                                    border: 1px solid lightgray;
                                    border-radius: 50%;
                                    background-color: #fff;
                                    top: 50%;
                                    left: -12px;
                                    transform: translate(-100%, -50%);
                                    z-index: 1;
                                }
                                &::after {
                                    position: absolute;
                                    content: " ";
                                    width: 12px;
                                    height: 12px;
                                    left: -16px;
                                    top: 50%;
                                    border-radius: 50%;
                                    transform: translate(-100%, -50%);
                                    background-color: white;
                                    z-index: 2;
                                }
                            }
                        }
                    }
                }
                .answer-checkbox {
                    padding: 8px 80px;
                    position: relative;
                    z-index: 0;
                    input {
                        opacity: 0;
                        width: 100%;
                        position: absolute;
                        height: 100%;
                        z-index: 5;
                        left: 0px;
                        top: 0px;
                        &:hover {
                            cursor: pointer;
                        }
                        &:checked + label{
                            svg {
                                opacity: 1;
                                transition: opacity .2s ease-out;
                            }
                        }
                        &:checked + label{
                            font-weight: 600;
                            &::before{
                                border: 1px solid $font-color;
                            }
                        }
                    }
                    label {
                        position: relative;
                        margin-bottom: 0px;
                        font-size: 20px;
                        line-height: 23px;
                        grid-column-start: 2;
                        &::before {
                            content: "";
                            position: absolute;
                            width: 20px;
                            height: 20px;
                            border: 1px solid lightgray;
                            border-radius: 2px;
                            background-color: #fff;
                            top: 0;
                            left: -16px;
                            transform: translateX(-100%);
                            z-index: 1;
                        }
                        svg {
                            opacity: 0;
                            position: absolute;
                            width: 12px;
                            transform: translateX(-100%);
                            left: -20px;
                            z-index: 1;
                            transition: opacity .1s ease-out;
                            height: 20px;
                            &>g>g {
                                fill: $font-color !important;
                            }
                        }
                    }
                }
            }
            .percentage-wrapper {
                width: 100%;
                margin: 40px auto 80px;
                position: relative;
                height: 113px;
                display: flex;
                justify-content: space-between;
                padding: 0px 20px;
                @include media-breakpoint-down(sm) {
                    padding: 0px;
                    margin: 0px;
                }
                &:before {
                    content: "";
                    background-color: $blue;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                    box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                    left: 0px;
                    top: 0px;
                    z-index: -1;
                    @include media-breakpoint-down(sm) {
                        border-radius: 0px;
                        width: 100vw;
                        left: -60px;
                    }
                    @include media-breakpoint-down(xs) {
                        left: -32px;
                    }
                }
                div {
                    display: block;
                    border-radius: 50%;
                    position: relative;
                    align-self: center;
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &.prev {
                        width: 46px;
                        height: 46px;
                        background-color: rgba($black, .2);
                        svg {
                            width: 12px;
                            path {
                                fill: $white;
                            }
                        }
                    }
                    &.next, &.finish {
                        width: 64px;
                        height: 64px;
                        background-color: $white;
                        box-shadow: 0 12px 46px 0 rgba(0,0,0,0.15);
                        position: absolute;
                        right: 20px;
                        svg {
                            width: 16px;
                            path {
                                fill: $blue;
                            }
                        }
                    }
                }
                .percentage-completed {
                    width: calc(100% - 40px - 46px - 64px - 40px);
                    height: 8px;
                    background-color: $white;
                    border-radius: 4px;
                    position: absolute;
                    left: calc(20px + 46px + 20px);
                    .percentage {
                        height: 100%;
                        border-radius: 4px;
                        background-color: $yellow;
                        span {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    .results-overview {
        min-height: calc(100vh - 118px);
        background-color: $blue;
        .results-wrapper {
            @include max-width-small;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: calc(100vh - 118px);
            align-items: center;
            .grade-percentage {
                margin-bottom: 20px;
                background-color: $white;
                width: 60px;
                height: 180px;
                position: relative;
                div {
                    position: absolute;
                    width: 100%;
                    bottom: 0px;
                    &.min-grade {
                        background-color: transparent;
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0px;
                            left: 50%;
                            transform: translateX(-50%);
                            height: 100%;
                            width: calc(100% + 40px);
                            border-top: dashed black 2px;
                            z-index: 2;
                        }
                    }
                    &.grade {
                        background-color: $yellow;
                    }
                }
            }
            .grades {
                text-align: center;
                color: $white;
                padding-bottom: 40px;
                div {
                    &:nth-of-type(1) {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 600;
                    }
                    &:nth-of-type(2) {
                        font-size: 14px;
                        line-height: 16px;
                        padding-top: 4px;
                    }
                }
            }
            .button {
                margin-bottom: 20px;
            }
        }
    }

    .all-results {
        padding: 40px 0px;
        @include media-breakpoint-down(sm) {
            padding: 40px 0px 0px;
        }
        .results-container {
            @include max-width-small;
            box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
            border-radius: 16px;
            background-color: white;
            position: relative;
            width: 100%;
            margin-bottom: 40px;
            label{
                font-size: 20px;
            }
            .title {
                font-size: 22px;
                font-weight: bold;
                line-height: 25px;
                padding: 24px 32px 0px;
                margin-bottom: 32px;
            }
            .true-false {
                display: block;
                width: fit-content;
                position: absolute;
                top: 24px;
                right: 32px;
            }
            .img-container {
                background-color: $blue;
                border-radius: 16px;
                width: calc(100% - 64px);
                margin: 0 auto;
                padding: 24px;
                margin-bottom: 32px;
                img {
                    max-width: 100%;
                }
            }
            .extra-info{
                font-size: 14px;
                border-radius: 8px;
                background: #EBF3FB;
                padding: 8px 8px 8px 33px;
                margin-top: 5px;
                position: relative;
                svg{
                    position: absolute;
                    top: 10px;
                    left: 8px;
                    width: 16px;
                    height: 16px;
                }
            }
            .question {
                padding: 0px 32px;
            }
            .answers-container {
                font-size: 20px !important;
                .incorrect-option, .correct-option{
                    margin-top: -0.2rem;
                }
                &.checkbox {
                    padding-bottom: 32px;
                    &.incorrect {
                        .answer {
                            .correct-option {
                                grid-column-start: 2;
                            }
                        }
                    }
                    &.correct {
                        .answer {
                            &.correct {
                                input {
                                    &:checked + label {
                                        svg {
                                            display: block;
                                            &>g>g{
                                                fill: $font-color;
                                            }
                                        }
                                    }
                                }
                                label {
                                    color: $font-color;
                                    svg {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                    .answer {
                        display: flex;
                        padding: 8px 80px;
                        position: relative;
                        &.incorrect {
                            label.type {
                                color: $red;
                            }
                        }
                        &.correct {
                            label.type {
                                color: $green-l1;
                            }
                        }
                        input {
                            opacity: 0;
                            width: 100%;
                            position: absolute;
                            height: 100%;
                            z-index: 5;
                            left: 0px;
                            top: 0px;
                            &:hover {
                                cursor: pointer;
                            }
                            &:checked + label{
                                &::before{
                                    border: 1px solid $font-color;
                                }
                            }
                        }
                        label {
                            position: relative;
                            &::before {
                                content: "";
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border: 1px solid lightgray;
                                border-radius: 2px;
                                background-color: #fff;
                                top: 0px;
                                left: -16px;
                                transform: translateX(-100%);
                                z-index: 1;
                            }
                            svg {
                                position: absolute;
                                left: -26px;
                                transform: translateX(-50%);
                                width: 12px;
                                z-index: 1;
                                height: 20px;
                                top: 0px;
                                &>g>g {
                                    fill: $font-color;
                                }
                            }
                        }
                        .option-correct {
                            grid-column-start: 2;
                        }
                    }
                }
                &.radio {
                    .answer {
                        display: flex;
                        width: 100%;
                        margin-top: 32px;
                        bottom: 0;
                        .answer-container {
                            padding: 16px 32px;
                            border-top: 1px solid $grey-l1;
                            width: 100%;
                            text-align: center;
                            position: relative;
                            &:nth-of-type(1) {
                                border-right: 1px solid $grey-l1;
                            }
                            .radio-container {
                                input {
                                    opacity: 0;
                                    width: 100%;
                                    position: absolute;
                                    height: 100%;
                                    z-index: 5;
                                    left: 0px;
                                    top: 0px;
                                    &:hover {
                                        cursor: pointer;
                                    }
                                    &:checked + label::after{
                                        background-color: $font-color;
                                    }
                                    &:checked + label{
                                        font-weight: 600;
                                        &::before{
                                            border: 1px solid $font-color;
                                        }
                                    }
                                }
                                label {
                                    position: relative;
                                    margin-bottom: 0px;
                                    font-size: 16px;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        width: 20px;
                                        height: 20px;
                                        border: 1px solid lightgray;
                                        border-radius: 50%;
                                        background-color: #fff;
                                        top: 50%;
                                        left: -12px;
                                        transform: translate(-100%, -50%);
                                        z-index: 1;
                                    }
                                    &::after {
                                        position: absolute;
                                        content: " ";
                                        width: 12px;
                                        height: 12px;
                                        left: -16px;
                                        top: 50%;
                                        border-radius: 50%;
                                        transform: translate(-100%, -50%);
                                        background-color: white;
                                        z-index: 2;
                                    }
                                }
                            }
                        }
                    }
                }
                .answer{
                    label{
                        min-width: 50px;
                        font-size: 16px;
                    }
                }
            }
        }
        .results-overview-navigation {
            @include max-width-small;
            display: flex;
            height: 126px;
            padding: 0px 20px;
            position: relative;
            width: 100%;
            @include media-breakpoint-down(xs) {
                width: 100vw;
                padding: 0px 32px;
            }
            &:before {
                content: "";
                background-color: $blue;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 16px;
                box-shadow: 0 10px 40px 0 rgba(0,0,0,0.15);
                left: 0px;
                top: 0px;
                z-index: -1;
                @include media-breakpoint-down(sm) {
                    border-radius: 0px;
                    width: 100vw;
                    left: -60px;
                }
                @include media-breakpoint-down(xs) {
                    left: 0px;
                }
            }
            .button {
                &.prev {
                    width: 46px;
                    height: 46px;
                    border-radius: 50%;
                    background-color: rgba($black, .2);
                    align-self: center;
                    border: none;
                    position: relative;
                    svg {
                        width: 12px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        path {
                            fill: $white;
                        }
                    }
                }
                &.re-do {
                    width: 240px;
                    height: 64px;
                    background-color: $yellow;
                    border-radius: 35px;
                    color: $white;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 21px;
                    align-self: center;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    @include media-breakpoint-down(xs) {
                        position: unset;
                        left: unset;
                        transform: translateX(0px);
                        justify-self: end;
                    }
                }
            }
        }
    }

    &.start-exam {
        background-color: $blue;
        height: calc(100vh - 118px);
        padding: 40px 0px;
        @include media-breakpoint-down(sm) {
            padding: 0px 0px 40px;
        }
        .intro {
            @include max-width-small();
            display: flex;
            align-items: center;
            flex-direction: column;
            .exam-title {
                position: unset;
                transform: translate(0, 0);
                margin-bottom: 40px;
            }
            .intro-icon {
                height: 100px;
                width: 100px;
                border-radius: 50%;
                border: 8px $white solid;
                position: relative;
                box-sizing: content-box;
                svg {
                    width: 50px;
                    height: auto;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    &>g>g {
                        fill: $white;
                    }
                }
            }
            .intro-description {
                color: $white;
                font-size: 28px;
                font-weight: bold;
                line-height: 25px;
                margin: 32px 0px;
            }
        }
        .start-exam-container {
            .text {
                @include max-width-small();
                color: white;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
                margin-bottom: 60px;
            }
            .button {
                margin: 0px auto 24px;
            }
        }
    }
}
