
.lookup-view-row{
    align-items: center;
    flex-direction: column;
    .input-wrapper{
        padding: 60px 40px 48px 40px;
        margin: 60px auto;
        border-radius: 16px;
        background-color: #ffffff;
        position: relative;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 24px;
            background-color: $blue;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px; 
        }
        span{
            margin-bottom: 24px;
            font-size: 24px;
            @media (max-width: 480px){
                font-size: 22px;
                font-weight: 700;
            }
        }
        label{
            margin-bottom: 0;
            display: block;
            margin-top: 30px;
        }
        input{
            width: 100%;
            border-radius: 8px;
            height: 48px;
            border: 1px solid $blue;
            padding-left: 12px;
            font-size: 16px;
            color: $font-color;
            font-weight: 800;
        }
        button{
            height: 48px;
            border-radius: 8px;
            position: absolute;
            right: 5%;
        }
    }
    .exam-overview-wrapper{
        margin-top: 40px;
        li{
            font-size: 18px;
            line-height: 28px;
        }
    }
}
