.payment-view {
    padding: 40px 24px;
    .bill {
        border-radius: 8px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
        padding: 20px;
        .product {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid $grey;
            .title {
                display: flex;
                align-items: center;
                p {
                    margin-bottom: 0;
                    margin-left: 16px;
                    font-size: 18px;
                    font-weight: bold;
                    line-height: 21px;
                }
                .caption {
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 18px;
                    font-weight: 900;
                    line-height: 20px;
                    border: 2px solid white;
                    &.red {
                        background-color: $red;
                    }
                    &.blue {
                        background-color: $blue;
                    }
                    &.green {
                        background-color: $green;
                    }
                    &.purple {
                        background-color: $purple;
                    }
                }
            }
            .price {
                font-size: 14px;
                font-weight: 300;
                line-height: 16px;
                span {
                    font-size: 18px;
                }
            }
        }
        .buyer {
            div {
                padding-top: 12px;
                p {
                    margin-bottom: 0px;
                    &.label {
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 16px;
                        color: $font-color-light;
                    }
                }
            }
        }
    }
    .payment {
        padding-top: 40px;
        p {
            color: $font-color;
            text-align: center;
        }
        .payment-button {
            border: none;
            height: 55px;
            width: 190px;
            border-radius: 40px;
            margin: 20px auto;
            display: block;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
        }
        .millifaersla-info{
            border-radius: 8px;
            background-color: $white;
            box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
            padding: 20px;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
            overflow: hidden;
        }
    }
}