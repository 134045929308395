.blog-list-block {
    .blogs{
        a:hover,
        a:active{
            text-decoration: none !important;
            .blog-image{
                img{
                    transform: scale(1.03);
                    transition: transform .2s linear;
                }
            }
        }
        .blog-card{
            border-radius: 16px;
            height: 325px;
            .blog-image{
                z-index: -1;
                img{
                    object-fit: cover;
                }
                .no-img-bg{
                    background-color: $black;
                    img{
                        margin-bottom: 6rem;
                    }
                }
            }
            .description {
                background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
                box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
                height: 147px;
                color: $white;
                padding: 0 30px;
                h3{
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 38px;
                    text-transform: uppercase;
                    @include media-breakpoint-down(sm) {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }
                p{
                    line-height: 20px !important;
                    max-height: 62px;
                    overflow: hidden;
                }
            }
        }
    }
    a.btn{
        height: 55px;
        width: 160px;
        border-radius: 32px;
        background-color: $blue;
        color: $white;
        border: none;
        outline: none;
        transition: background-color .2s ease-in-out;
        &:hover{
            background-color: darken($blue, 5%);
            transition: background-color .2s ease-in-out;
        }
    }
}
