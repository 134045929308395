.paragraph-block {
    .paragraph {
        ul {
            margin-top: -12px;
            padding-left: 24px;
            list-style: none;
            li {
                position: relative;
                font-size: 17px;
                line-height: 26px;
                &:before {
                    content: "";
                    background-color: $yellow;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    position: absolute;
                    left: -14px;
                    top: 11px;
                }
            }
        }
        p {
            font-size: 17px;
            line-height: 26px;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
            &.bold {
                margin-top: 40px;
                margin-bottom: 8px;
            }
            a {
                font-weight: 600;
                color: $blue;
                &:hover {
                    text-decoration: none;
                    color: $blue-d1;
                }
            }
        }
    }
}