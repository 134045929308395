@mixin max-width-small {
    max-width: 750px;
    margin: 0 auto;
    @include media-breakpoint-down(lg) {
        width: calc(100% - 250px);
    }
    @include media-breakpoint-down(sm) {
        width: calc(100% - 120px);
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% - 64px);
    }
}