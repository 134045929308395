.page-formpage {
    .form-container {
        padding-top: 32px;
        padding-bottom: 80px;
        form {
            .form-group {
                display: flex;
                flex-direction: column;
                min-height: 68px;
                margin-bottom: 16px;
                input, textarea {
                    height: 47px;
                    border-radius: 4px;
                    border: 1px $grey solid;
                    order: 2;
                }
                label {
                    order: 1;
                    font-size: 14px;
                    line-height: 15px;
                    padding: 0px; 
                    margin-bottom: 4px;
                }
                &>.col-form-label{
                    font-weight: bold;
                }
                textarea {
                    height: 300px;
                }
                label{
                    order: 1;
                }
                ul{
                    padding-left: 0;
                    order: 2;
                    li{
                        list-style-type: none;
                        label{
                            position: relative;
                            input{
                                height: 34px;
                                border-radius: 4px;
                                border: 1px #cecece solid;
                                order: 2;
                                vertical-align: middle;
                                margin-bottom: 4px;
                                margin-right: 4px;
                                font-size: 44px;
                            }
                        }
                    }
                }
                &.checkbox{
                    &> div:first-child {
                        order: 2;
                    }
                    input{
                        height: auto;
                    }
                }
            }
            .submit-button {
                width: 190px;
                height: 55px;
                border-radius: 40px;
                border: none;
                background-color: $blue;
                display: block;
                margin: 40px auto 0px;
                color: $white;
                font-size: 18px;
                line-height: 21px;
                font-weight: 600;
            }
        }
    }
}

.thank-you-container {
    min-height: calc(100vh - 500px);
    display: flex;
    align-items: center;
    .form-container {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        p {
            font-size: 18px;
            line-height: 22px;
            color: $font-color-light;
            margin: 0;
        }
    }
}
