.page-classpage {
    .intro {
        position: relative;
        margin-bottom: 60px;
        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
        }
        .number {
            width: 100px;
            height: 100px;
            margin: 0 auto;
            border-radius: 50%;
            border: solid 10px white;
            position: absolute;
            left: 50%;
            top: -50%;
            transform: translate(-50%, 0%);
            box-shadow: 0 18px 48px 0 rgba(0,0,0,0.15);
            &.red {
                background-color: $red;
            }
            &.blue {
                background-color: $blue;
            }
            &.green {
                background-color: $green;
            }
            &.purple {
                background-color: $purple;
            }
            @include media-breakpoint-down(md) {
                width: 74px;
                height: 74px;
                border: solid 6px white;
            }
            span {
                font-size: 44px;
                font-weight: 900;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $white;
                @include media-breakpoint-down(md) {
                    font-size: 28px;
                }
            }
        }
        .title {
            text-align: center;
            font-size: 40px;
            padding-top: 54px;
            font-weight: 900;
            position: relative;
            @include media-breakpoint-down(md) {
                font-size: 32px;
            }
            @include media-breakpoint-down(sm) {
                font-size: 24px;
                font-weight: bold;
                line-height: 32px;          
            }
        }
    }
    .sessions-container {
        .session {
            margin: 20px 0px;
            height: fit-content;
            height: -moz-fit-content;
            background-color: $white;
            border-radius: 8px;
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
            .session-content {
                height: 100%;
                position: relative;
                span.title {
                    color: $grey;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;
                    width: calc(100% - 100px);
                    display: block;
                    height: 100%;
                    padding: 25px 30px;
                    text-decoration: none;
                    &:hover {
                        cursor: default;
                        text-decoration: none;
                    }
                }
                .list-button {
                    width: 80px;
                    height: 80px;
                    position: absolute;
                    right: 0px;
                    border-radius: 4px;
                    top: 0px;
                    transform: translateY(0px);
                    cursor: pointer;
                    .icon {
                        height: 28px;
                        width: 32px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        margin: 0px;
                        position: absolute;
                        border-radius: 4px;
                        background-color: rgba($grey, 0);
                        transition: 0s background-color;
                        svg {
                            width: 14px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) rotate(-180deg);
                            transition: .3s transform;
                            path {
                                fill: $grey;
                            }
                        }
                    }
                }
                .class-list {
                    display: none;
                    list-style: none;
                    padding-left: 32px;
                    padding-bottom: 25px;
                    li {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 40px;
                        color: $font-color;
                        display: flex;
                        align-items: center;
                        &:hover {
                            cursor: default;
                        }
                        a {
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 40px;
                            color: $font-color;
                            display: flex;
                            align-items: center;
                            &:hover {
                                text-decoration: none;
                            }
                        }
                        .exam-icon {
                            display: inline-block;
                            height: 18px;
                            width: 18px;
                            border-radius: 50%;
                            border: 1px solid $font-color;
                            position: relative;
                            margin-right: 12px;
                            svg {
                                width: 8px;
                                position: absolute;
                                top: 50%;
                                height: 8px;
                                transform: translate(-50%, -50%);
                                left: 50%;
                                &>g>g {
                                    fill: $font-color;
                                }
                            }
                        }
                    }
                }
                &.open {
                    .list-button {
                        .icon {
                            background-color: rgba($grey, 1);
                            transition: .4s background-color;
                            svg {
                                transform: translate(-50%, -50%) rotate(0deg);
                                path {
                                    fill: $grey-d2;
                                }
                            }
                        }
                    } 
                    .class-list {
                        display: block;
                    }   
                }
            }
            &.finished {
                .session-content {
                    padding: 25px 30px 8px;
                    .title-container {
                        display: flex;
                        justify-content: space-between;
                        padding: 0px 0px 16px 0px;
                        .title {
                            padding: 0;
                            width: auto;
                            color: $font-color;
                        }
                        .completed {
                            display: flex;
                            span {
                                font-size: 14px;
                                line-height: 16px;
                                padding-right: 14px;
                                align-self: center;
                                color: $font-color-light;
                            }
                            svg {
                                width: 30px;
                                height: auto;
                                &>g>g {
                                    fill: $yellow;
                                }
                            }
                        }
                    }
                    .percentage {
                        display: block;
                        height: 5px;
                        border-radius: 2.5px;
                        background-color: #ffffff;
                        span {
                            width: 100%;
                            background-color: $yellow;
                            display: block;
                            height: 5px;
                            border-radius: 2.5px;
                        }
                    }.list-button {
                        position: unset;
                        width: 100%;
                        height: 30px;
                        margin-top: 8px;
                        transition: .4s background-color;
                    }
                    &.open {
                        .list-button {
                            background-color: rgba($black, .1);
                            transition: .4s background-color;
                            .icon {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            &.current {
                &.red {
                    background-color: $red;
                }
                &.blue {
                    background-color: $blue;
                }
                &.green {
                    background-color: $green;
                }
                &.purple {
                    background-color: $purple;
                }
                .session-content {
                    padding: 25px 30px 8px;
                    .title-link {
                        width: 100%;
                        color: $white;
                        display: flex;
                        justify-content: space-between;
                        padding: 0px 0px 16px 0px;
                        &:hover {
                            cursor: pointer;
                            text-decoration: none;
                        }
                        span.title {
                            font-size: 24px;
                            font-weight: 600;
                            line-height: 28px;
                            padding: 0;
                            width: auto;
                            color: $white;
                            &:hover {
                                cursor: pointer;
                            }
                        }
                        .onward-button {
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 21px;
                            position: relative;
                            padding: 4px 30px 4px 40px;
                            .icon {
                                position: absolute;
                                height: 14px;
                                width: 14px;
                                transform: rotate(90deg) translateX(-50%);
                                right: 0;
                                top: 50%;
                                svg {
                                    vertical-align: unset;
                                }
                            }
                        }
                    }

                    .percentage {
                        display: block;
                        height: 5px;
                        border-radius: 2.5px;
                        background-color: $white;
                        span {
                            width: 66%;
                            height: 5px;
                            background-color: $yellow;
                            display: block;
                            border-radius: 2.5px;
                        }
                    }
                    .list-button {
                        position: unset;
                        width: 100%;
                        height: 30px;
                        margin-top: 8px;
                        transition: .4s background-color;
                        .icon {
                            svg {
                                path {
                                    fill: $white;
                                }
                            }
                        }
                    }
                    .class-list{
                        padding: 0px;
                        margin: 8px 0px 16px;
                        li {
                            color: $white;
                            a {
                                color: $white;
                                font-size: 16px;
                                font-weight: 500;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                            .exam-icon {
                                border: 1px solid $white;
                                svg {
                                    &>g>g {
                                        fill: $white;
                                    }
                                }
                            }
                        }
                    }
                    &.open {
                        .list-button {
                            background-color: rgba($black, .2);
                            transition: .4s background-color;
                            .icon {
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
}