#bottom-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: white;
  p {
    margin-bottom: 0;
  }
  .content {
    position: relative;
    padding: 25px;
    .close-container {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
    }
  }
}