.intro-text {
    top: 255px;
    width: 537px;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-down(sm) {
        width: calc(100% - 120px);
        top: 390px;
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% - 64px);
    }
    .title {
        width: fit-content;
        width: -moz-fit-content;
        margin: 0 auto;
        @include media-breakpoint-down(sm) {
            width: 310px;
        }
        @include media-breakpoint-down(xs) {
            width: 283px;
        }
        h1 {	
            font-size: 118px;	
            font-weight: 900;	
            line-height: 89px;
            font-family: 'Roboto', sans-serif;
            text-shadow: 0px 7px 24px rgba(0,0,0,0.25);
            text-transform: uppercase;
            color: $white;
            @include media-breakpoint-down(sm) {
                font-size: 68px;
                line-height: 51px;
            }
            @include media-breakpoint-down(xs) {
                font-size: 62px;
                line-height: 47px;
            }
            b {
                color: $yellow;
            }
        }
    }
    .list {
        margin-top: 64px;
        text-align: center;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            width: 75px;
            height: 4px;
            background-color: $yellow;
            top: -16px;
            left: 50%;
            transform: translateX(-50%);
        }
        ul {
            list-style: none;
            padding: 0px;
            li {
                position: relative;
                color: $white;
                font-size: 18px;
                line-height: 28px;
                font-weight: 600;
                margin-left: 20px;
                display: inline;
                @include media-breakpoint-down(xs) {
                    font-size: 16px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    background-color: #E9D16F;
                    left: -14px;
                    top: 10px;
                }
            }
        }
    }
    .button {
        height: 57px;
        width: 240px;
        border: 2px solid $white;
        border-radius: 32px;
        margin-top: 48px;
        a {
            color: $white;
            font-size: 18px;
            line-height: 21px;
            padding: 16px 0px;
            &:hover {
                text-decoration: none;
            }
        }
    }
}