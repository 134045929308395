.page-blogpage{
    nav{
        background: linear-gradient(90deg, #13A995 0%, #2B82D1 47.08%, #7E4CA2 100%) !important;
        .page-caption{
            h1{
                font-size: 36px !important;
                font-weight: 700;
                margin-bottom: 0px !important;
                @include media-breakpoint-down(sm){
                    font-size: 30px !important;
                }
            }
        }
    }
    .blog-page{
        padding-top: 60px;
        @include media-breakpoint-down(sm){
            padding-top: 0;
        }
        .blog-header{
            margin-bottom: 60px;
            @include media-breakpoint-down(sm){
                margin-bottom: 20px;
            }
            .image-container{
                border-radius: 16px;
                @include media-breakpoint-down(sm){
                    border-radius: 0;
                }
                img{
                    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
                    height: auto;
                    max-height: 500px;
                    object-fit: cover;
                }
                .intro{
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);
                    bottom: 0;
                    height: 70%;
                    padding: 200px 100px;
                    @include media-breakpoint-down(md){
                        height: auto;
                        padding: 0 30px 20px 47px;
                    }
                    h1, p{
                        color: $white;
                    }
                    h1{
                        font-weight: 700;
                    }
                }
            }
        }
        h3{
            font-size: 24px;
            font-weight: 700;
            margin-top: 40px;
        }
    }
}