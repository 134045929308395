.user-profile {
  @include max-width-small;
  margin-top: 80px;
  margin-bottom: 80px;
  h2 {
    margin-bottom: 20px;
  }
  form {
    .form-group {
      label {
        font-size: 14px;
        line-height: 15px;
        color: $font-color-light;
      }
      input, select {
        height: 47px;
        border: 1px solid $grey-l1;
        &:focus {
          outline: none;
          border: 1px solid $grey-d1;
          box-shadow: none;
        }
      }
    }
    .continue-btn {
      height: 55px;
      width: 190px;
      border-radius: 40px;
      border: none;
      background-color: $blue;
      color: white;
      margin: 0 auto;
      display: block;
      margin-top: 40px;
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
    }
  }
}