.page-formpage {
    footer {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.page-moduleitempage, .page-exampage, .page-modulepage {
    footer {
        display: none;
    }
}

.shop {
    footer {
        background-color: $white;
        padding-bottom: 56px;
        padding-top: 56px;
        margin-bottom: 0px;
        .logo {
            margin-top: 0px!important;
            padding-top: 96px!important;
            svg {
                @include media-breakpoint-down(sm) {
                    top: 0px!important;
                    transform: translate(-50%, 0%)!important;
                }
            }
        }
    }
}

footer {
    margin-bottom: 60px;
    .footer {
        .logo {
            position: relative;
            margin-bottom: 24px;
            margin-top: 80px;
            svg {
                position: absolute;
                left: -24px;
                transform: translate(-100%, -25%);
                @include media-breakpoint-down(md){
                    left: 50%;
                    transform: translate(-50%, -133%);
                }
                g>g{
                    fill: $grey-d2;
                }
            }
            span {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 30px;
                letter-spacing: 0.75px;
                align-self: center;
            }
        }
        .footer-content {
            border-top: 2px solid $grey;
            padding: 60px 10px 0px;
            @include media-breakpoint-down(md) {
                .links{
                    min-width: 120px;
                }
                .text {
                    margin-left: auto;
                    margin-right: unset!important;
                    width: 280px;
                }
                .contact {
                    margin-left: auto;
                    margin-top: 24px;
                }
            }
            @include media-breakpoint-down(sm) {
                flex-direction: column;
                align-items: center;
                border-top: none;
                padding: 24px 10px 0px;
                .links {
                    width: fit-content;
                    width: -moz-fit-content;
                    text-align: center;
                    margin: 0 auto;
                }
                .text {
                    margin: 40px 0 0 0;
                }
                .contact {
                    margin: 40px 0 0 0;
                }
            }
            .links {
                width: fit-content;
                width: -moz-fit-content;
                margin-right: auto;
                display: flex;
                flex-direction: column;
                a {
                    color: $font-color;
                    text-decoration: none;
                    margin-bottom: 8px;
                    &:hover {
                        color: $blue;
                    }
                }
            }
            img{
                max-height: 100%;
            }
            a {
                color: $font-color;
                &:hover {
                    text-decoration: none;
                }
                span {
                    margin-top: 20px;
                }
                &.phone {
                    margin-right: 50px;
                    img{
                        height: 50px;
                    }
                }
                &.email {
                    margin-right: 50px;
                    img{
                        width: 50px;
                    }
                }
                &.facebook {
                    img{
                        width: 40px;
                    }
                    .addon {
                        display: none;
                    }
                }
            }
        }
    }
}