.goal-block {
    height: fit-content;
    height: -moz-fit-content;
	width: 100%;
	border-radius: 16px;
	background-color: $white;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
    padding: 26px 32px 16px;
    margin-bottom: 24px;
    h3 {
        font-size: 20px;
        font-weight: 600;
    }
    ul.goal-list {
        padding-left: 18px;
        list-style: none;
        li {
            position: relative;
            font-size: 17px;
            line-height: 26px;
            @include media-breakpoint-down(sm) {
                font-size: 16px;
            }
            &:before {
                content: "";
                background-color: $yellow;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                position: absolute;
                left: -14px;
                top: 11px;
            }
        }
    }
}