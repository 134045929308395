.course-block {
    .courses {
        .course-card {
            border-radius: 16px;
            height: 250px;
            margin-bottom: 32px;
            &.red{
                background-color: $red;
                .card-bottom{
                    background-color: $red;
                }
                .card-top {
                    .img {
                        &:after{
                            background-color: rgba($red, 0.5);
                        }
                    }
                    .number {
                        background-color: $red;
                    }
                }
            }
            &.blue{
                background-color: $blue;
                .card-bottom{
                    background-color: $blue;
                }
                .card-top {
                    .img {
                        &:after{
                            background-color: rgba($blue, 0.5);
                        }
                    }
                    .number {
                        background-color: $blue;
                    }
                }
            }
            &.green{
                background-color: $green;
                .card-top {
                    .img {
                        &:after{
                            background-color: rgba($green, 0.5);
                        }
                    }
                    .number {
                        background-color: $green;
                    }
                }
                .card-bottom{
                    background-color: $green;
                }
            }
            &.purple{
                background-color: $purple;
                .card-top {
                    .img {
                        &:after{
                            background-color: rgba($purple, 0.5);
                        }
                    }
                    .number {
                        background-color: $purple;
                    }
                }
                .card-bottom{
                    background-color: $purple;
                }
            }
            &.multi{
                background: $multi-background-2;
                .card-top {
                    .img {
                        &:after{
                            background: $multi-background-2-transparent;
                        }
                    }
                    .number {
                        background: $multi-background-2;
                    }
                }
                .card-bottom{
                    background-color: $multi-background-2;
                }
            }
            .card-top {
                padding: 32px 6px;
                height: 190px;
                &.no-caption {
                    padding-top: 89px;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 32px;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 57px;
                        width: 57px;
                        background-image: url(/static/img/17.svg);
                        background-size: contain;
                        background-repeat: no-repeat;
                    }
                }
                .img {
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    border-radius: 16px 16px 0px 0px;
                    img {
                        filter: blur(4px);
                    }
                    &:after {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }
                .number {
                    width: 57px;
                    height: 57px;
                    margin: 0 auto;
                    border-radius: 50%;
                    border: solid 5px $white;
                    span {
                        font-size: 24px;
                        line-height: 28px;
                        font-weight: 900;
                        position: absolute;
                        color: $white;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
                .title {
                    font-size: 30px;
                    line-height: 35px;
                    color: $white;
                    font-weight: 900;
                    margin-top: 20px;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 55px;
                        height: 2px;
                        background-color: $yellow;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -16px;
                    }
                }
            }
            .card-bottom{
                height: 60px;
                left: 0;
                bottom: 0;
                .price{
                    color: $white;
                    font-size: 28px;
                    font-weight: 300;
                    line-height: 60px;
                }
            }
        }
    }
}