.page-promopage {
    header {
        background-color: transparent;
    }
}
.shop {
    min-height: 100vh;
    background: $multi-background;
    header {
        background-color: transparent;
        height: auto;
        &.hamburger-open {
            .navbar{
                button.navbar-toggler {
                    top: 84px;
                    @include media-breakpoint-down(sm) {
                        top: 48px;
                    }
                }
            }
        }
        .navbar {
            padding: 60px 80px;
            @include media-breakpoint-down(sm) {
                padding: 40px 30px;
            }
            .brand-container {
                .navbar-brand {
                    @include media-breakpoint-down(sm) {
                        margin-top: 56px;
                        img {
                            justify-self: center;
                            width: 120px;
                        }
                        span {
                            font-size: 34px;
                        }
                    }
                }
            }
            button.navbar-toggler {
                .navbar-toggler-icon {
                    margin-top: 0px;
                    @include media-breakpoint-down(sm) {
                        margin-top: 8px;
                    }
                    &.open {
                        margin-top: 0px;
                    }
                }
            }
        }
    }
    section {
        min-height: calc(100vh - 570px);
    }
}

.lang{
    position: relative;
    border: 2px white solid;
    height: 40px;
    border-radius: 20px;
    padding: 0px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    svg{
        margin-right: 10px;
    }
    .current{
        width: 24px;
        text-align: center;
    }
    .menu{
        display: none;
        a{
            color: white;
            text-decoration: none;
            margin-left: 10px;
        }
    }
    &.visible{
        .menu{
            display: inline;
            .current{
                border: 1px solid white;
                border-radius: 50%;
            }
        }
        .current{
            border: 1px solid white;
            border-radius: 50%;
            width: 26px;
        }
    }
}

.page-homepage {
    header{
        height: 100vh;
        background-color: transparent;
        position: relative;
        display: block;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $multi-background;
        }
        &.hamburger-open {
            .navbar{
                button.navbar-toggler {
                    top: 80px;
                    @include media-breakpoint-down(sm) {
                        top: 40px;
                    }
                }
                .personal-info-btn {
                    pointer-events: none;
                    cursor: default;
                }
            }
        }
        &.personal-info-open {
            .navbar {
                button.navbar-toggler {
                    pointer-events: none;
                    cursor: default;
                }
            }
        }
        .header-img {
            display: block;
            position: absolute;
            z-index: -1;
            top: 0;
            height: 100vh;
            width: 100vw;
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        .navbar {
            padding: 60px 80px;
            @include media-breakpoint-down(sm) {
                padding: 40px 30px;
            }
            .brand-container {
                .navbar-brand {
                    @include media-breakpoint-down(sm) {
                        margin-top: 56px;
                        img {
                            width: 120px;
                        }
                        span {
                            font-size: 34px;
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                .login{
                    order: 1;
                    margin-left: 0;
                    margin-right: 15px;
                }
                .lang{
                    order: 2;
                    margin-left: 0;
                    margin-right: auto;
                }
                .navbar-toggler{
                    order: 3;
                }
                .brand-container{
                    order: 4;
                }
            }
        }
    }
}

.page-classpage, .page-exampage {
    header {
        .navbar {
            .navbar-brand {
                @include media-breakpoint-down(md) {
                    span {
                        display: none;
                    }
                }
                @include media-breakpoint-down(sm) {
                    display: none;
                }
            }
        }
    }
}

.page-moduleitempage, .page-blogpage {
    header {
        &.hamburger-open {
            .navbar {
                .navbar-toggler {
                    @include media-breakpoint-down(sm) {
                        right: 32px;                
                    }
                }
            }
        }
        .navbar {
            height: 100%;
            .navbar-toggler {
                align-self: center;
                @include media-breakpoint-down(sm) {
                    order: 3;
                }
                .navbar-toggler-icon {
                    margin-top: 0px;
                }
            }
            .brand-container {
                margin-right: auto;
                @include media-breakpoint-down(sm) {
                    width: 0;
                }
                .navbar-brand {
                    @include media-breakpoint-down(md) {
                        span {
                            display: none;
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        display: none;
                    }
                }
            }
            .page-caption {
                left: 50%;
                transform: translateX(-50%);
                &:hover {
                    cursor: default;
                }
                @include media-breakpoint-down(sm) {
                    order: 2;
                    margin-right: 0;
                }
                .caption {
                    position: relative;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    border: solid 2px white;
                    margin-right: 24px;
                    span {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        font-weight: 600;
                    }
                }
                .title {
                    font-size: 20px;
                    line-height: 26px;
                    text-transform: uppercase;
                    height: fit-content;
                    height: -moz-fit-content;
                    align-self: center;
                }
            }
            .login {
                align-self: center;
                @include media-breakpoint-down(sm) {
                    order: 1;
                }
            }
        }
    }
}

header {
    height: 118px;
    background-color: $blue;
    overflow-x: hidden;
    &.green {
        background-color: $green;
    }
    &.purple {
        background-color: $purple;
    }
    &.hamburger-open {
        .navbar{
            button.navbar-toggler {
                position: fixed;
                right: 80px;
                top: 48px;
                @include media-breakpoint-down(sm) {
                    right: 60px;
                }
                @include media-breakpoint-down(xs) {
                    right: 32px;
                }
            }
            .personal-info-btn {
                pointer-events: none;
                cursor: default;
            }
        }
    }
    &.personal-info-open {
        .navbar {
            button.navbar-toggler {
                pointer-events: none;
                cursor: default;
            }
        }
    }
    .header-img {
        display: none;
    }
    .navbar {
        padding: 25px 80px;
        display: flex;
        flex-direction: row;
        color: $white;
        position: relative;
        @include media-breakpoint-down(sm) {
            grid-template-columns: auto 1fr 32px;
            grid-template-rows: auto;
            padding: 25px 32px;
        }
        .brand-container {
            margin-right: auto;
            @include media-breakpoint-down(sm) {
                order: 3;
                width: 100%;
                justify-content: center;
                display: flex;
            }
            .navbar-brand {
                display: flex;
                width: fit-content;
                width: -moz-fit-content;
                @include media-breakpoint-down(sm) {
                    padding: 0px;
                    text-align: center;
                    margin: 0 auto;
                    flex-direction: column;
                    img {
                        width: 40px;
                    }
                }
                img {
                    margin: 0 auto;
                }
                span {
                    margin-left: 16px;
                    color: white;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 30px;
                    letter-spacing: 0.75px;
                    align-self: center;
                    @include media-breakpoint-down(sm) {
                        font-size: 20px;
                        margin-left: 0;
                        margin-top: 12px;
                    }
                }
            }
        }
        .login {
            border: 2px white solid;
            height: 40px;
            border-radius: 20px;
            margin-right: 32px;
            margin-left: 32px;
            padding: 0px 12px;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
                border: none;
                height: auto;
                padding: 0px;
                align-self: start;
                margin-right: auto;
                .nav-link {
                    padding: 0px;
                    img {
                        height: 24px;
                    }
                    span {
                        display: none;
                    }
                }
            }
            .nav-link {
                padding: 6px;
                color: $white;
                img {
                    margin-right: 8px;
                }
                span {
                    font-size: 16px;
                }
            }
        }
        button.navbar-toggler{
            font-size: 1.25rem;
            line-height: 1;
            background-color: transparent;
            height: fit-content;
            width: -moz-fit-content;
            z-index: 5;
            padding: 0px;
            align-self: center;
            &:focus {
                outline: none;
            }
            @include media-breakpoint-up(lg) {
                &:hover {
                    span.navbar-toggler-icon {
                        span:nth-child(1) {
                            width: 24px;
                            right: 3px
                        }
                        span:nth-child(4) {
                            width: 24px;
                            right: 3px;
                        }
                    }
                }
            }
            @include media-breakpoint-down(sm) {
                align-self: start;
            }
            span.navbar-toggler-icon{
                width: 30px;
                height: 20px;
                cursor: pointer;
                position: relative;
                left: 0;
                display: block;
                margin-top: 8px;
                &.hide {
                    display: none;
                }
                &.open {
                    span {
                        margin-top: 0px;
                        background-color: $grey-d2;
                        &:nth-child(1) {
                            top: 11px;
                            width: 0% !important;
                            left: 50%;
                        }
                        &:nth-child(2) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                            top: calc(50% - 2px);
                            left: -1px;
                        }
                        
                        &:nth-child(3) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                            top: calc(50% - 2px);
                            left: -1px;
                        }
                        
                        &:nth-child(4) {
                            top: 11px;
                            width: 0% !important;
                            left: 50%;
                        }
                    }
                }
                span {
                    background-color: $white;
                    display: block;
                    position: absolute;
                    height: 3px;
                    width: 100%;
                    border-radius: 9px;
                    opacity: 1;
                    right: 0;
                    transition: .20s ease-in-out;
                    &:nth-child(1) {
                        top: 0px;
                    }
                    &:nth-child(2), &:nth-child(3) {
                        top: 8px;
                    }
                    
                    &:nth-child(4) {
                        top: 16px;
                    }
                }
            }
        }
    }
    .menu-wrapper-container {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0px;
        overflow: hidden;
        z-index: 4;
        transition: width .5s .25s;
        @include media-breakpoint-down(sm) {
            transition: width .3s .2s;
        }
        &.open {
            transition: width .5s;
            width: 650px;
            @include media-breakpoint-down(sm) {
                width: calc(75vw + 50px);
                transition: width .3s;
            }
            @include media-breakpoint-down(xs) {
                width: calc(90vw + 50px);
            }
        }
        .menu-wrapper {
            width: 600px;
            height: 100vh;
            background-color: $white;
            position: absolute;
            top: 0;
            right: -700px;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
            z-index: 4;
            transition: right .5s .25s;
            @include media-breakpoint-down(sm){
                width: 75vw;
                right: -100vw;
                transition: right .3s .2s;
                &.open {
                    transition: right .3s;
                }
            }
            @include media-breakpoint-down(xs){
                width: 90vw;
            }
            &.open {
                transition: right .5s;
                right: 0px;
                ul {
                    z-index: 1;
                    opacity: 1;
                    transition: opacity .2s .5s;
                    @include media-breakpoint-down(sm){
                        transition: opacity .15s .3s;
                    }
                }
            }
            ul {
                transition: opacity .2s 0s;
                z-index: -1;
                opacity: 0;
                list-style: none;
                margin-top: 130px;
                margin-left: 80px;
                @include media-breakpoint-down(sm){
                    margin-left: 60px;
                    transition: opacity .15s 0s;
                }
                @include media-breakpoint-down(xs){
                    margin-left: 40px;
                }
                li {
                    font-size: 24px;
                    line-height: 64px;
                    position: relative;
                    width: fit-content;
                    width: -moz-fit-content;
                    &:after {
                        content: "";
                        width: 35px;
                        height: 2px;
                        background-color: $blue;
                        position: absolute;
                        left: -55px;
                        top: 50%;
                        transform: translateY(-50%);
                        opacity: 0;
                        transition: opacity .2s;
                    }
                    &:hover {
                        &:after {
                            opacity: 1;
                            transition: opacity .2s;
                        }
                    }
                    a {
                        color: $font-color;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
    .personal-info-menu-container {
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0px;
        overflow-x: hidden;
        overflow-y: scroll;
        z-index: 5;
        transition: width .5s .25s;
        @include media-breakpoint-down(sm) {
            transition: width .3s .2s;
            right: unset;
            left: 0px;
        }
        &.open {
            transition: width .5s;
            width: 650px;
            @include media-breakpoint-down(sm) {
                width: calc(75vw + 50px);
                transition: width .3s;
            }
            @include media-breakpoint-down(xs) {
                width: calc(90vw + 50px);
            }
        }
        .personal-info-menu {
            position: absolute;
            min-height: 100vh;
            width: 600px;
            top: 0;
            right: -600px;
            padding: 44px 60px;
            background-color: $white-d1;
            box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.5);
            z-index: 4;
            transition: right .5s .25s;
            @include media-breakpoint-down(sm) {
                padding: 44px 40px;
            }
            @include media-breakpoint-down(xs) {
                padding: 44px 24px;
            }
            &.open {
                transition: right .5s;
                right: 0;
                p, a, .close-btn-container {
                    z-index: 1;
                    opacity: 1;
                    transition: opacity .2s .5s;
                    @include media-breakpoint-down(sm){
                        transition: opacity .15s .3s;
                    }
                }
            }
            @include media-breakpoint-down(sm){
                right: unset;
                width: 75vw;
                left: -75vw;
                transition: left .3s .2s;
                &.open {
                    transition: left .3s;
                    left: 0px;
                }
            }
            @include media-breakpoint-down(xs){
                width: 90vw;
                left: -90vw;
            }
            .close-btn-container {
                z-index: -5;
                opacity: 0;
                transition: opacity .2s 0s;
                background-color: transparent;
                height: fit-content;
                width: fit-content;
                width: -moz-fit-content;
                z-index: 5;
                padding: 0px;
                position: absolute;
                right: 0;
                right: 83px;
                top: 48px;
                border: none;
                &:focus {
                    outline: none;
                }
                @include media-breakpoint-down(sm){
                    right: 40px;
                    left: unset;
                    transition: opacity .15s 0s;
                }
                @include media-breakpoint-down(xs){
                    right: 24px;
                }
                span.close-btn{
                    width: 30px;
                    height: 20px;
                    cursor: pointer;
                    position: relative;
                    display: block;
                    span {
                        background-color: $grey-d2;
                        z-index: 5;
                        height: 3px;
                        width: 100%;
                        border-radius: 9px;
                        position: absolute;
                        left: 1px;
                        top: calc(50% - 2px);
                        &:nth-child(1) {
                            -webkit-transform: rotate(45deg);
                            -moz-transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }
                        
                        &:nth-child(2) {
                            -webkit-transform: rotate(-45deg);
                            -moz-transform: rotate(-45deg);
                            -o-transform: rotate(-45deg);
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            .title-container {
                display: flex;
                width: calc(100% - 80px);
                svg {
                    margin-right: 20px;
                    align-self: center;
                    width: 23px;
                    height: auto;
                    &>g>g {
                        fill: $font-color;
                    }
                }
                p {
                    margin-bottom: 0px;
                    font-size: 24px;
                    line-height: 24px;
                }
            }
            .user-info-container {
                .my-courses {
                    margin: 40px 0px;
                    p {
                        margin-bottom: 8px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                        .course {
                            width: 100%;
                            border-radius: 8px;
                            padding: 20px 24px;
                            color: $white;
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 20px;
                            .text-container {
                                display: flex;
                                margin-bottom: 20px;
                                .caption {
                                    margin-right: 20px;
                                    height: 32px;
                                    width: 32px;
                                    border-radius: 50%;
                                    border: 3px white solid;
                                    display: flex;
                                    align-content: center;
                                    justify-content: center;
                                    font-weight: 600;
                                }
                                .title {
                                    margin: 0px;
                                    text-transform: capitalize;
                                    font-size: 24px;
                                    font-weight: 600;
                                    line-height: 28px;
                                    align-self: center;
                                    @include media-breakpoint-down(xs) {
                                        font-size: 20px;
                                    }
                                }
                            }
                            .percentage-container {
                                border-radius: 2.5px;
                                width: 100%;
                                height: 5px;
                                background-color: $white;
                                position: relative;
                                .percentage {
                                    width: 20%;
                                    height: 100%;
                                    position: absolute;
                                    background-color: $yellow;
                                }
                            }
                            &.red {
                                background-color: $red;
                            }
                            &.blue {
                                background-color: $blue;
                            }
                            &.green {
                                background-color: $green;
                            }
                            &.purple {
                                background-color: $purple;
                            }
                        } 
                    }
                }
                .courses {
                    margin: 40px 0px;
                    p {
                        margin-bottom: 8px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    a {
                        &:hover {
                            text-decoration: none;
                        }
                        .course {
                            width: 100%;
                            border-radius: 8px;
                            padding: 20px 24px;
                            color: $white;
                            margin-bottom: 20px;
                            display: flex;
                            flex-direction: row;
                            .caption {
                                margin-right: 20px;
                                height: 32px;
                                width: 32px;
                                border-radius: 50%;
                                border: 3px white solid;
                                display: flex;
                                align-content: center;
                                justify-content: center;
                                font-weight: 600;
                            }
                            .title {
                                margin: 0px;
                                text-transform: capitalize;
                                font-size: 24px;
                                font-weight: 600;
                                line-height: 28px;
                                align-self: center;
                                @include media-breakpoint-down(xs) {
                                    font-size: 20px;
                                }
                            }
                            .lock {
                                margin-left: auto;
                                @include media-breakpoint-down(sm) {
                                    height: auto;
                                    width: 20px;
                                    align-self: center;
                                }
                            }
                            &.red {
                                background-color: $red;
                            }
                            &.blue {
                                background-color: $blue;
                            }
                            &.green {
                                background-color: $green;
                            }
                            &.purple {
                                background-color: $purple;
                            }
                        }
                    }
                }
                .user-info {
                    width: 100%;
                    border-radius: 8px;
                    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2);
                    padding: 20px 24px;
                    background-color: white;
                    h3 {
                        margin: 8px 0px;
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;
                    }
                    p {
                        margin: 0px;
                    }
                    .contact-info {
                        padding: 16px 0px;
                        display: block;
                    }
                    .update-info {
                        width: 240px;
                        height: 40px;
                        border-radius: 40px;
                        border: 1px solid $grey;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: $font-color;
                        text-transform: uppercase;
                        font-size: 14px;
                        font-weight: bold;
                        margin: 24px auto 16px;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
            .log-out {
                width: 240px;
                height: 56px;
                border-radius: 40px;
                border: 1px solid $blue;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $font-color;
                text-transform: capitalize;
                font-size: 18px;
                font-weight: 600;
                margin: 50px auto 10px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
