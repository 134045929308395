
.products {
    padding-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .product-container {
        padding: 30px 24px 24px;
        width: 100%;
        max-width: 450px;
        .product {
            border: 2px solid $white;
            margin: 0px auto;
            border-radius: 16px;
            padding: 50px 20px 20px;
            position: relative;
            background-color: rgba(0, 0, 0, 0.1);
            &.double-caption {
                .caption:nth-of-type(1) {
                    z-index: 1;
                    transform: translate(-88%, -50%);
                }
                .caption:nth-of-type(2) {
                    transform: translate(-12%, -50%);
                }
            }
            .caption {
                height: 60px;
                width: 60px;
                border-radius: 50%;
                border: 7px solid $white;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                position: absolute;
                top: 0px;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 24px;
                font-weight: 900;
                line-height: 27px;
                &.red {
                    background-color: $red;
                }
                &.blue {
                    background-color: $blue;
                }
                &.green {
                    background-color: $green;
                }
                &.purple {
                    background-color: $purple;
                }
            }
            .title {
                font-size: 28px;
                font-weight: bold;
                letter-spacing: 0.7px;
                line-height: 44px;
                color: $white;
                text-align: center;
                text-transform: uppercase;
                padding-bottom: 16px;
                position: relative;
                &:after {
                    content: "";
                    position: absolute;
                    width: 104px;
                    height: 4px;
                    background-color: $yellow;
                    left: 50%;
                    top: 100%;
                    transform: translate(-50%, -100%);
                }
            }
            .price {
                font-size: 28px;
                font-weight: 300;
                line-height: 22px;
                text-align: center;
                color: $white;
                padding: 20px 0px;
                .isk {
                    font-size: 20px;
                }
            }
            a {
                height: 51px;
                width: 168px;
                border-radius: 31.6px;
                background-color: $white;
                box-shadow: 0 8px 32px 0 rgba(0,0,0,0.15);
                display: flex;
                justify-content: center;
                align-items: center;
                color: $font-color;
                font-size: 18px;
                font-weight: bold;
                line-height: 21px;
                margin: 0 auto;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
