.review-block {
    margin-bottom: 80px;
    .reviews {
        .review-card {
            background-color: $white;
            box-shadow: 0 1px 1px 0 rgba(0,0,0,0.15);
            border-radius: 16px;
            padding: 40px 20px 20px;
            height: fit-content;
            cursor: default;
            flex-basis: 33%;
            margin-bottom: 40px;
            &:nth-of-type(3) {
                margin-right: 0px;
            }
            .quotation {
                width: 25px;
                height: 25px;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translate(-50%, -50%);
            }
            div {
                font-size: 16px;
                line-height: 22px;
                &.name {
                    font-weight: 600;
                }
            }
        }
    }
}