html{
    scroll-behavior: smooth; 
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: 'Roboto Condensed', sans-serif;
        color: $font-color;
        background-color: $white-d1;
        &.hamburger-open, &.personal-info-open {
            height: 100vh;
            overflow-y: hidden;
        }
        &.show_banner {
            padding-bottom: 74px;
            @media (max-width: 874px) {
                padding-bottom: 98px;
            }
            @media (max-width: 484px) {
                padding-bottom: 122px;
            }
        }
    
        h1{}
    
        h2 {
            font-size: 36px;
            font-weight: 700;
            line-height: 48px;
            text-transform: uppercase;
            @include media-breakpoint-down(sm) {
                font-size: 28px;
                line-height: 37px;
            }
        }
    
        h3{}
    
        h4{
            font-size: 18px;
            font-weight: 700;
            line-height: 22px; 
        }
    
        h5{}
    
        h6{}

        .ltr{
            direction: ltr !important;
        }
    }

    [dir="rtl"].media {
        float: right;
        margin-right: 20px;
    }

    [dir="rtl"].html {
        .answer-checkbox{
            display: flex;
            input {
                order: 2;
            }
            label{
                order: 1;
                text-align: right;
                padding-right: 38px;
    
                &::before{
                    left: unset !important;
                    right: -16px;
                 }
            }
        }
    }
}

.font-color{
    color: $font-color;
}

.block-intro{
    margin-top: 60px;
    margin-bottom: 30px;
    .title {
        padding-top: 40px;
        @include media-breakpoint-down(sm) {
            padding-top: 32px;
        }
    }
    .description {
        font-size: 18px;
        line-height: 24px;
    }
}

.no-access{
    ul{
        li{
            list-style: none;
            position: relative;
            &::before{
                content: '';
                background-color: $blue;
                height: 8px;
                width: 8px;
                position: absolute;
                left: -16px;
                top: 6px;
                border-radius: 50%;
            }
        }
    }
    a{
        color: $font-color;
        border-bottom: 1px solid $blue;
        text-decoration: none;
    }
}
.spinner {
    display: inline-block;
    width: 60px;
    height: 60px;
    border: 3px solid rgb(94 94 94 / 21%);
    border-radius: 50%;
    border-top-color: #494949;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
    position: relative;
    left: 50%;
    top: 250px;

    @include media-breakpoint-down(md) {
        left: 47%;
    }

    @include media-breakpoint-down(sm) {
        left: 44%;
    }
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}