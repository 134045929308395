.questions-block {
    .open-all {
        margin: 0 0 20px auto;
        display: block;
        width: fit-content;
        width: -moz-fit-content;
        &:hover {
            cursor: pointer;
        }
        svg {
            width: 24px;
            transform: rotate(0deg);
            transition: transform .2s linear;
        }
        &.open {
            svg {
                transform: rotate(45deg);
                transition: transform .2s linear;
            }
        } 
    }
    .question-container {
        background-color: white;
        border-radius: 4px;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
        padding: 16px 16px 16px 24px;
        margin-bottom: 16px;
        position: relative;
        a.toggle-button {
            position: absolute;
            top: 13px;
            left: 0px;
            transform: translateX(-50%);
            svg {
                transform: rotate(0deg);
                transition: transform .2s linear;
            }
            &.open {
                svg {
                    transform: rotate(45deg);
                    transition: transform .2s linear;
                }
            }
        }
        .answer {
            display: none;
            &.show {
                display: block;
            }
        }
    }
}