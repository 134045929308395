.embed-block{
    margin: 40px 0px;
    @include media-breakpoint-down(sm) {
        margin: 24px 0px;
    }
    .responsive-object{
        position: relative;
        > div{
            padding-bottom: 56.25%;
            position: relative;
            width: 100%;
            iframe{
                top: 0;
                left: 0;
                position: absolute;
                height: 100%;
                width: 100%;
            }
        }
    }
}