.staff-block {
    .staffmember {
        height: 300px;
        border-radius: 16px;
        background: $blue;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
        position: relative;
        .staff-img {
            height: 175px;
            width: 175px;
            border: 6px solid $white-d1;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            z-index: 1;
            margin: 0 auto;
            img {
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
        .staff-text {
            background: white;
            height: 50%;
            bottom: 0;
            border-radius: 0px 0px 16px 16px;
            padding-top: 68px;
            .email {
                color: $blue;
                &:hover {
                    color: $blue-d1;
                    text-decoration: none;
                }
            }
        }
    }
}