.page-promopage {
    .course {
        color: $white;
        &.red {
            .course-top {
                .img {
                    &:after {
                        background-color: rgba($red, .4);
                    }
                }
                .number {
                    background-color: $red;
                }
            }
            .course-bottom {
                background-color: $red;
            }
        }
        &.blue {
            .course-top {
                .img {
                    &:after {
                        background-color: rgba($blue, .4);
                    }
                }
                .number {
                    background-color: $blue;
                }
            }
            .course-bottom {
                background-color: $blue;
            }
        }
        &.green {
            .course-top {
                .img {
                    &:after {
                        background-color: rgba($green, .4);
                    }
                }
                .number {
                    background-color: $green;
                }
            }
            .course-bottom {
                background-color: $green;
            }
        }
        &.purple {
            .course-top {
                .img {
                    &:after {
                        background-color: rgba($purple, .4);
                    }
                }
                .number {
                    background-color: $purple;
                }
            }
            .course-bottom {
                background-color: $purple;
            }
        }
        .course-top {
            height: 442px;
            padding-top: 40px;
            @include media-breakpoint-down(md) {
                height: 352px;
            }
            .img {
                top: -118px;
                height: 560px;
                z-index: -1;
                @include media-breakpoint-down(md) {
                    height: 470px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgba($blue, .4);
                }
                img {
                    filter: blur(4px);
                    min-width: calc(100% + 8px);
                    min-height: calc(100% + 8px);
                    height: auto;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .number {
                width: 122px;
                height: 122px;
                border-radius: 50%;
                border: solid 10px white;
                @include media-breakpoint-down(md) {
                    width: 74px;
                    height: 74px;
                    border: solid 8px white;
                }
                span {
                    font-size: 48px;
                    line-height: 28px;
                    font-weight: 900;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    @include media-breakpoint-down(md) {
                        font-size: 29px;
                    }
                }
            }
            .title {
                text-align: center;
                font-size: 60px;
                font-weight: 900;
                line-height: 79px;
                margin-top: 20px;
                text-transform: uppercase;
                @include media-breakpoint-down(md) {
                    font-size: 42px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 36px;
                }
            }
            .price {
                text-align: center;
                font-size: 56px;
                font-weight: 300;
                line-height: 44px;
                margin-top: 64px;
                @include media-breakpoint-down(md) {
                    font-size: 28px;
                    margin-top: 32px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    width: 110px;
                    height: 8px;
                    background-color: $yellow;
                    left: 50%;
                    transform: translateX(-50%);
                    top: -46px;
                    @include media-breakpoint-down(md) {
                        top: -30px;
                        height: 6px;
                        width: 100px;
                    }
                }
            }
        }
        .course-bottom {
            .content {
                @include max-width-small;
                display: flex;
                padding: 56px 0px;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
                .description {
                    margin-right: 60px;
                    a {
                        color: $white;
                    }
                    @include media-breakpoint-down(md) {
                        margin-right: 0;
                        text-align: center;
                    }
                }
                .buttons {
                    display: flex;
                    flex-direction: column;
                    height: fit-content;
                    height: -moz-fit-content;
                    width: fit-content;
                    width: -moz-fit-content;
                    @include media-breakpoint-down(md) {
                        margin: 0 auto;
                    }
                    a {
                        margin-bottom: 20px;
                        width: 238px;
                        height: 68px;
                        border-radius: 40px;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 21px;
                        text-align: center;
                        padding: 22px;
                        &:hover {
                            text-decoration: none;
                        }
                        &:nth-child(1) {
                            color: $font-color;
                            background-color: $white;
                        }
                        &:nth-child(2) {
                            color: $white;
                            background-color: rgba($color: #000000, $alpha: 0.25);
                        }
                    }
                }
            }
        }
    }
}