
$green-l1: #34B843;
$green: #13A995;
$purple: #7E4CA2;
$yellow: #FAAD24;
$red: #DD2121;

$blue-d1: #1B5B98;
$blue: #3C90DD;

$white-d1: #F2F5F8;
$white: #ffffff;

$grey-l1: #d9d9d9;
$grey: #cecece;
$grey-d1: #999999;
$grey-d2: #585858;

$black: #131926;

$font-color: $grey-d2;
$font-color-light: #4c4c4c;

$multi-background: linear-gradient(90deg, rgba(19, 169, 149, 0.92) 0%, rgba(60, 144, 221, 0.92) 47.08%, rgba(126, 76, 162, 0.92) 100%);

$multi-background-2: linear-gradient(90deg, rgba(60, 144, 221, 0.92) 16%, rgba(19, 169, 149, 0.92) 100%);
$multi-background-2-transparent: linear-gradient(90deg, rgba(60, 144, 221, 0.5) 16%, rgba(19, 169, 149, 0.5) 100%);