.paragraph-image-block {
    width: calc(100% - 200px);
    margin: 60px auto;
    border-radius: 16px;
    background-color: $white;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.15);
    @include media-breakpoint-down(md) {
        width: 100%;
    }
    .content {
        height: fit-content;
        height: -moz-fit-content;
        padding: 26px 32px;
        font-size: 17px;
        line-height: 26px;  
    }
    &.fill {
        .img-container {
            border-radius: 16px 16px 0px 0px;
            width: 100%;
            height: 300px;
            overflow: hidden;
            position: relative;
            @include media-breakpoint-down(sm) {
                height: 200px;
            }
            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-height: 100%;
                min-width: 100%;
            }
        }
    }
    &.center {
        .img-container {
            border-radius: 16px 16px 0px 0px;
            width: 100%;
            height: 300px;
            background-color: $blue;
            position: relative;
            img {
                margin: 0 auto;
                position: absolute;
                left: 50%;
                bottom: -40px;
                transform: translateX(-50%);
                width: auto;
                max-height: 300px;
                max-width: 80%;
            }
        }
        .content {
            padding: 60px 32px 26px;
        }
    }
}